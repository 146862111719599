/* You can add global styles to this file, and also import other style files */

/** boutons ladda. */
@import url("../node_modules/ladda/dist/ladda-themeless.min.css");
//@import "../node_modules/angular-calendar/css/angular-calendar.css";

/** Nos CSS PITI */
@import "src/assets/css/piti";

html, body {
  height: 100%;
}

body {
  margin: 0
}
