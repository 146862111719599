/* *************************************************************** *
 * Styles et surcharges PITI des CSS de notre application Angular. *
 * *************************************************************** */

.ngx-datatable.bootstrap.core-bootstrap .datatable-header {
  background-color: #f7f7f8;
}

select.form-control.is-valid {
  background-image: none;
}

angular-editor.ng-invalid.ng-touched .angular-editor .angular-editor-wrapper .angular-editor-textarea {
  border: 1px solid red !important;
}

angular-editor.ng-invalid.ng-dirty .angular-editor .angular-editor-wrapper .angular-editor-textarea {
  border: 1px solid red !important;
}

div.angular-editor-textarea.disabled {
  background-color: #f7f7f8 !important;
}

/**
* Surcharges spécifiques à l'angular2-multiselect-dropdown
*/

/* corps de l'angular2-multiselect-dropdown. */
.c-btn {
  border-radius: .35rem !important;
  border: 1px solid #E0E0E0;
}

.c-btn.disabled {
  background-color: #f7f7f8 !important;
}

/* Liste à checkbox contenue dans l'angular2-multiselect-dropdown. */
.list-area label {
  text-transform: none !important;
}

select {
  background-image: none !important;
}

/** option diasable dans les select. */
select option:disabled {
  font-style: italic;
  text-decoration: line-through;
  color: lightgrey;
}

input.ng-invalid, textarea.ng-invalid, select.ng-invalid {
  border-color: #f55252;
}

input.ng-invalid, textarea.ng-invalid, select.ng-invalid option, angular-editor.ng-invalid .angular-editor-textarea {
  padding-right: 1.25em !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: 0.5em 0.5em;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!-- Created with Inkscape (http://www.inkscape.org/) --%3E%3Csvg xmlns:dc='http://purl.org/dc/elements/1.1/' xmlns:cc='http://creativecommons.org/ns%23' xmlns:rdf='http://www.w3.org/1999/02/22-rdf-syntax-ns%23' xmlns:svg='http://www.w3.org/2000/svg' xmlns='http://www.w3.org/2000/svg' version='1.1' width='32.275391' height='30.46875' id='svg3033'%3E%3Cmetadata id='metadata3041'%3E%3Crdf:RDF%3E%3Ccc:Work rdf:about=''%3E%3Cdc:format%3Eimage/svg+xml%3C/dc:format%3E%3Cdc:type rdf:resource='http://purl.org/dc/dcmitype/StillImage' /%3E%3Cdc:title%3E%3C/dc:title%3E%3C/cc:Work%3E%3C/rdf:RDF%3E%3C/metadata%3E%3Cdefs id='defs3039' /%3E%3Cg id='g3065'%3E%3Cg id='text3035' style='font-size:100px;font-family:arial'%3E%3Cpath d='M 0,14.355469 2.2460938,7.421875 C 7.4218645,9.2448552 11.181626,10.82363 13.525391,12.158203 12.906885,6.2663426 12.581365,2.2136123 12.548828,0 l 7.080078,0 c -0.09768,3.2227258 -0.472027,7.2591801 -1.123047,12.109375 3.35284,-1.692646 7.193982,-3.2551444 11.523438,-4.6875 l 2.246094,6.933594 c -4.134146,1.367244 -8.186877,2.278702 -12.158204,2.734375 1.985652,1.725314 4.785129,4.801483 8.398438,9.228515 L 22.65625,30.46875 C 20.768205,27.89718 18.53839,24.397835 15.966797,19.970703 13.557926,24.560595 11.442043,28.059941 9.6191406,30.46875 L 3.8574219,26.318359 C 7.6334528,21.663463 10.335273,18.587294 11.962891,17.089844 7.763661,16.276098 3.7760348,15.364641 0,14.355469' id='path3063' style='font-size:100px;font-family:arial' fill='%23f55252'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}

input.ng-invalid:focus, textarea.ng-invalid:focus {
  border-color: #f55252;
  box-shadow: 0 0 0 0.2rem #f5525240;
}

input.ng-invalid ~ .custom-control-label:before {
  border-color: #f55252;
}

input.ng-invalid ~ .custom-control-label {
  color: #f55252;
}

angular-editor.ng-invalid .angular-editor-textarea {
  border: 1px solid #f55252 !important;
}

angular-editor.ng-valid.ng-dirty .angular-editor-textarea {
  border: 1px solid #40c057 !important;
}

angular-editor.ng-valid.ng-dirty .angular-editor-textarea:focus {
  border-color: #40c057;
  box-shadow: 0 0 0 0.2rem #40c05740;
}


.radio-label {
  text-transform: uppercase;
  font-size: .85rem;
  font-weight: 500;
}

/** Ng select */
.ng-select.ng-invalid {
  border: 1px solid #f55252 !important;
  border-radius: .35rem !important;
}


